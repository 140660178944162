<template>
  <div class="password">
    <!-- 白名单 -->
    <el-form
      :model="deviceData"
      ref="deviceData"
      label-width="90px"
      class="demo-ruleForm"
      :rules="rules"
    >
      <el-form-item prop="use">
        <el-radio-group v-model="deviceData.use" @change="setRadio">
          <el-radio :label="1">{{ $t("public.Enable") }}</el-radio>
          <el-radio :label="0">{{ $t("public.Forbidden") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.whiteListPage.userList') + '：'"
        prop="userNames"
      >
        <el-input
          style="width: 400px"
          size="small"
          v-model="deviceData.userNames"
          type="textarea"
          :rows="8"
          :placeholder="$t('AppManage.whiteListPage.userPlaceholder')"
          :disabled="deviceData.use == 0"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          @click="submitForm('deviceData')"
          type="primary"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t("AppManage.whiteListPage.UserReg")));
      } else {
        callback();
      }
    };
    //电话正则验证---暂时不需要 (!/^1[3456789]\d{9}$/.test(value)) ----  !/^[0-9a-zA-Z_]{1,}$/
    const validateName = (rule, value, callback) => {
      if (value != undefined) {
        if (value.indexOf("\n") > -1) {
          var arr = value.split("\n");
          console.log(arr);
          arr = arr.filter((element) => {
            if (!/^[0-9a-zA-Z_]{1,}$/.test(element)) {
              console.log(
                element,
                "element",
                /^[0-9a-zA-Z_]{1,}$/.test(element)
              );
              return element;
            }
          });
          console.log(arr);
          if (arr.length > 0) {
            callback(this.$t("AppManage.whiteListPage.UserVoid"));
          } else {
            callback();
          }
        } else {
          // console.log(value)
          if (value != "") {
            if (!/^[0-9a-zA-Z_]{1,}$/.test(value)) {
              callback(this.$t("AppManage.whiteListPage.UserReg"));
            } else {
              callback();
            }
          } else {
            callback();
          }
        }
      } else {
        callback();
      }
    };
    return {
      deviceData: {
        use: null,
        userNames: "",
      },
      rules: {
        userNames: [{ validator: validateInput, trigger: ["blur", "change"] }],
      },
    };
  },
  created() {
    this.getUserList();
  },
  mounted() {},
  methods: {
    checkSpecificKey(str) {
      if (/[\u4E00-\u9FA5]/g.test(str) == true) {
        console.log(str, "res");
        return false;
      } else {
        var specialKey =
          "/ |/|~|!|@|#|\\$|%|^|&|*|(|)|+|{|}|:|<|>|?|[|]|,|/|;|；|，|。|.|'|`|=|\\||/";
        for (var i = 0; i < str.length; i++) {
          if (specialKey.indexOf(str.substr(i, 1)) != -1) {
            return false;
          }
        }
      }
      return true;
    },
    // 判断禁用
    setRadio(val) {
      if (val == 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // 查询
    async getUserList() {
      const parmas = {};
      let res = await this.$axios.post(
        "/httpServe/white/user/selectOne",
        parmas,
        true
      );
      console.log(res, "res");

      this.deviceData.use = res.data.use;

      this.deviceData.userNames = res.data.user
        .toString()
        .replaceAll(",", "\n");
    },
    // 保存
    submitForm(deviceData) {
      this.$refs[deviceData].validate((valid) => {
        if (valid) {
          this.saveData();
        } else {
          return false;
        }
      });
    },
    async saveData() {
      var arr = this.deviceData.userNames.split("\n");
      // 删除空字符串
      for (var i = arr.length - 1; i >= 0; i--) {
        if (arr[i] == "") {
          arr.splice(i, 1);
        }
      }
      const parmas = {
        use: this.deviceData.use,
        user: arr,
      };
      console.log(parmas, "保存参数");
      if (arr.length > 200) {
        this.$message({
          message: this.$t("AppManage.whiteListPage.userUdidNum"),
          type: "error",
          offset: 100,
        });
      } else if (this.deviceData.use == 1 && arr.length == 0) {
        this.$message({
          message: this.$t("AppManage.whiteListPage.userUdidUnEmpty"),
          type: "error",
          offset: 100,
        });
      } else {
        const res = await this.$axios.post(
          "/httpServe/white/user/update",
          parmas,
          true
        );
        if (res.data == 1) {
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.getUserList();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  padding-top: 5px;
  margin-left: 60px;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>