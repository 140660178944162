<template>
  <div class="password">
    <!-- 白名单 -->
    <el-form
      :model="deviceData"
      ref="deviceData"
      label-width="90px"
      class="demo-ruleForm"
    >
      <el-form-item prop="use">
        <el-radio-group v-model="deviceData.use" @change="setRadio">
          <el-radio :label="1">{{ $t("public.Enable") }}</el-radio>
          <el-radio :label="0">{{ $t("public.Forbidden") }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.whiteListPage.devicesList') + '：'"
        prop="udid"
      >
        <el-input
          style="width: 400px"
          size="small"
          v-model="deviceData.udid"
          type="textarea"
          :rows="8"
          :placeholder="$t('AppManage.whiteListPage.devPlaceholder')"
          :disabled="deviceData.use == 0"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          @click="submitForm('deviceData')"
          type="primary"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      deviceData: {
        use: null,
        udid: "",
      },
      rules: {
        udid: [
          {
            required: true,
            message: this.$t("AppManage.whiteListPage.devUdidVoid"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getUDID();
  },
  mounted() {},
  methods: {
    // 判断禁用
    setRadio(val) {
      if (val == 0) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // 查询
    async getUDID() {
      const parma = {};
      let res = await this.$axios.post(
        "/httpServe/white/device/udid/selectOne",
        parma,
        true
      );
      this.deviceData.udid = res.data.udid.toString().replaceAll(",", "\n");
      this.deviceData.use = res.data.use;
    },
    // 保存
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.saveData();
        } else {
          return false;
        }
      });
    },
    async saveData() {
      var arr = this.deviceData.udid.split("\n");
      // 删除空字符串
      for (var i = arr.length - 1; i >= 0; i--) {
        if (arr[i] == "") {
          arr.splice(i, 1);
        }
      }

      const parma = {
        use: this.deviceData.use,
        udid: arr,
      };
      console.log(parma, "保存参数");
      if (arr.length > 200) {
        this.$message({
          message: this.$t("AppManage.whiteListPage.devUdidNum"),
          type: "error",
          offset: 100,
        });
      } else if (this.deviceData.use == 1 && arr.length == 0) {
        this.$message({
          message: this.$t("AppManage.whiteListPage.devUdidUnEmpty"),
          type: "error",
          offset: 100,
        });
      } else {
        const res = await this.$axios.post(
          "/httpServe/white/device/udid/update",
          parma,
          true
        );

        if (res.data == 1) {
          this.$message({
            message: this.$t("public.SuccessfullySaved"),
            type: "success",
            offset: 100,
          });
          this.getUDID();
        }
      }
    },
  },
};
</script>
  
  <style lang='scss' scoped>
.password {
  padding-top: 5px;
  margin-left: 60px;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>