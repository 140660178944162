<template>
  <div>
    <el-tabs v-model="activeName" type="card"  @tab-click="handleLoad"  class="tabs" style="overflow: auto">
      <el-tab-pane :label="$t('AppManage.whiteListPage.DeviceWhitelist')" name="1">
        <deviceWhitelist :key="timer1" ></deviceWhitelist>
      </el-tab-pane>
      <el-tab-pane :label="$t('AppManage.whiteListPage.UserWhitelist')" name="2">
        <userWhitelist :key="timer2" ></userWhitelist>
      </el-tab-pane>
    </el-tabs> 
  </div>
</template>

  
<script>
// 应用管理-策略配置-白名单修改为tab页形式，包含设备白名单、用户白名单
import deviceWhitelist from './components/deviceWhitelist' //设备白名单
import userWhitelist from './components/userWhitelist' //用户白名单
export default {
  components: {
    deviceWhitelist,
    userWhitelist,
  },
  components: {deviceWhitelist, userWhitelist },
  data() {
    return {
      activeName: "1", //默认显示当前页面
      timer1: '',
      timer2: '',
      // timer3: '',
      // timer4: '',
    };
  },
  mounted() {
    
  },
  methods: {
    handleLoad(data) {
      let name = data.name
      if (name == 1) {
        this.timer1 = new Date().getTime()
      } else if (name == 2) {
        this.timer2 = new Date().getTime()
      } 
      // else if (name == 3) {
      //   this.timer3 = new Date().getTime()
      // } else if (name == 4) {
      //   this.timer4 = new Date().getTime()
      // }
    },


   

  },
};
</script>

<style lang="scss" scoped>
.tabs {
  height: 580px;
  border: 1px solid #dddd;
  margin-left: 10px;
  margin-top: 30px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  border-radius: 5px;
}
</style>